<template>
  <div>
    {{ data }}
  </div>
</template>

<script>
export default {
  name: 'ButtonsBlock',
  props: ['data']
}
</script>

<style>

</style>